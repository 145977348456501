import { useMemo, useState, useEffect } from "react";
import { useRecoilState, useRecoilValue } from "recoil";
import { useT } from "../../components/languages/t";
import { Table } from "../../components/table/table";
import { useList } from "../../useList";
import { AppStore } from "../../store/store";
import { useFilter } from "../../components/form/useFilter";
import { useLocation, useRoute } from "wouter";
import { displayStatusBadge } from "./vehicleStatusBadge";
import TableHeader from "../../components/table/TableHeader";
import SearchInput from "../../components/formElements/SearchInput";
import { useSearch } from "../../components/form/useSearch";
import { SquaresPlusIcon } from "@heroicons/react/24/outline";
import TableFilter from "../../components/table/TableFilter";
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import {useFleets} from "../../useFleets";

const re2 = new RegExp(/([a-z])(?=[0-9])/gi);
const re3 = new RegExp(/([0-9])(?=[a-z])/gi);


const VehicleList = () => {
  const { t, i18n } = useT();
  // Get all fleets
  const appState = useRecoilValue(AppStore);
  const { FM_API_URL } = process.env;

  // Get all fleets
  const fleetsNoperators = useFleets()
  const fleets = useMemo(() => { return Object.values(fleetsNoperators || {}).reduce((r, v) => {
    if(v.type === 'FLEET') {
      r[v.id] = v.name;
    }
    return r;
  }, {}); },[fleetsNoperators])

  const filterOpts = {
    fleet_id: {
      title: t(["fleetColumn", "Fleet"]),
      options: fleets,
    },
    status: {
      title: t(["statusColumn", "Status"]),
      options: {
        ACTIVE: t("Active"),
        INACTIVE: t("Inactive"),
      },
    },
  };

  const { list, handleListQuery } = useList("/cars/list");
  const { query, handleSearch, handleSearchEnter, clearSearch } = useSearch(
    "/cars/list",
    handleListQuery
  );
  const { filters, handleFilterChange } = useFilter(
    "/cars/list",
    handleListQuery,
    filterOpts
  );
  const [_, navigate] = useLocation();
  const [match, params] = useRoute("/vehicles/:id");
  console.log(fleets);
  const cols = useMemo(() => {
    return [
      {
        header: t(["plateColumn", "Number Plate"]),
        id: "subject",
        accessorFn: (r) => {
          return (
            <span className="whitespace-nowrap tabular-nums">
              {r.subject
                .replace(/\s/g, "")
                .replace(re2, "$1 ")
                .replace(re3, "$1 ")}
            </span>
          );
        },
      },
      {
        header: t(["fleetColumn", "Fleet"]),
        id: "fleet_id",
        accessorKey: "fleet_name",
      },
      {
        header: t(["statusColumn", "Status"]),
        id: "status",
        accessorFn: (r) => {
          return displayStatusBadge(r.status);
        },
      },
      {
        header: t(["totalFinesColumn", "Total Fines"]),
        accessorKey: "total_fines",
      },
      {
        header: t(["unpaidFinesColumn", "Unpaid Fines"]),
        accessorKey: "unpaid_fines",
      },
      {
        header: t(["amountDueColumn", "Amount due"]),
        accessorKey: "amount_due",
      },
      {
        header: t(["amountPaidColumn", "Amount paid"]),
        accessorKey: "amount_paid",
      },
    ];
  }, [i18n.language]);

  const onRowClick = (row) => {
    navigate(`/vehicles/${row.id}`);
  };

  console.log("list", list, "FILTERS", filters);

  return (
    <>
      <TableHeader title={t("Vehicles")}>
        <button
          onClick={() => {
            navigate(`/vehicles/new`);
          }}
          type="button"
          className="h-11 inline-flex items-center rounded gap-0.5 px-2 py-2.5 text-center text-sm font-medium text-blue-500 transition-all hover:bg-gray-100 focus:ring focus:ring-gray-100 disabled:cursor-not-allowed disabled:border-gray-100 disabled:bg-gray-50 disabled:text-gray-400"
        >
          <SquaresPlusIcon className="h-5 w-5 text-blue-500 mr-2" />
          {t("New Vehicle")}
        </button>
      </TableHeader>
      <div className="flex-[0_0_auto] border-b px-4 py-4 border-gray-100">
        <SearchInput
          query={query}
          handleSearch={handleSearch}
          handleSearchEnter={handleSearchEnter}
          clearSearch={clearSearch}
        />
      </div>
      <TableFilter filters={filters} onFilterChange={handleFilterChange} />
      <Table
        data={list.data}
        columns={cols}
        totalHits={list.totalHits}
        page={list.page}
        lastPage={list.totalHits >= 0 ? list.lastPage + 1 : 0}
        pageSize={list.pageSize}
        sortBy={""}
        sortDesc={false}
        filters={list.filters}
        search={list.search}
        fetchData={(p) => {
          return handleListQuery(p);
        }}
        onRowClick={onRowClick}
        selectedItemId={params && params.id}
        spinner={VehicleSpinner}
      />
    </>
  );
};

export default VehicleList;
