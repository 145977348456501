import { withTranslation } from 'react-i18next';

export function displayStatusBadge(status) {

  let color = "bg-red-50 text-red-500"
  let status_txt = "Inactive"
  if (status === 'ACTIVE') {
    color = "bg-green-50 text-green-500"
    status_txt = "Active"
  }

  return <TranslatedBadge color={color} status_txt={status_txt} />

}

const TranslatedBadge = withTranslation('fleet-management-admin')(function ({color, status_txt, t}) {
  return <span
    className={`text-center justify-center whitespace-nowrap inline-flex items-center gap-1 rounded px-3 py-2 text-xs font-semibold ${color}`}>
            {t(status_txt)}
          </span>
})
