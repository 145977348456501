import {useT} from "../../components/languages/t";
import FormTitle from "../../components/form/FormTitle";
import Form from "../../components/form/Form";
import {useResource} from "../../useResource";
import FormDetails from "../../components/form/FormDetails";
import format from "date-fns/format";
import parseISO from "date-fns/parseISO";
import isFuture from 'date-fns/isFuture'
import {useEffect, useState} from "react";
import {AppStore} from "../../store/store";
import {useRecoilState} from "recoil";
import Spinner from "../../components/spinner/Spinner";
import Map from "../../components/map/Map";
import TextInput from "../../components/formElements/TextInput";
import {CircleStackIcon} from "@heroicons/react/24/outline";
import * as y from "yup";
import {useValidate} from "../../components/form/useValidate";
import SupportText from "../../components/formElements/SupportText";
import {useReloadList} from "../../useList";
import { withTranslation } from 'react-i18next';
import VehicleSpinner from "../../components/spinner/VehicleSpinner";
import {ArrowDownTrayIcon} from "@heroicons/react/24/solid"

const re = /(\d{3,4})(\d{4})(\d{4})(\d{4})/
const re2 = new RegExp(/([a-z])(?=[0-9])/ig);
const re3 = new RegExp(/([0-9])(?=[a-z])/ig);


const { FM_API_URL } = process.env;

function fetchWithAuthentication(url, authToken) {
  const headers = new Headers();
  headers.set('Authorization', `Bearer ${authToken}`);
  return fetch(url, { headers });
}

async function getImageBlobs(token, fineUrls) {

  let blobs = []

  for (const fine_image_url of fineUrls) {

    const response = await fetchWithAuthentication(
      fine_image_url, token
    );

    // Create an object URL from the data.
    const blob = await response.blob();
    const objectUrl = URL.createObjectURL(blob);

    blobs.push(objectUrl);

  }

  return blobs;
}

const FineStatusBox = withTranslation('fleet-management-admin')(function ({data:r, t}) {

  let color = "bg-red-50 text-red-500"
  let status_txt = t("Payment Overdue")

  if (r.is_parking_fee === true) {
    color = "bg-purple-50 text-purple-500"
    status_txt = t("Parking Fee")
  } else if (r.status === 'PAID') {
    color = "bg-green-50 text-green-500"
    status_txt = t("Fine is paid. Thank you!")
  } else if(r.status === 'CLOSED' || r.status === 'EXPIRED') {
    color = "bg-green-50 text-green-500"
    status_txt = t("The fine is closed.")
  } else if(r.status === 'REVOKED' || r.status === 'REVOKED_INSPECTOR') {
    color = "bg-green-50 text-green-500"
    status_txt = t("The fine has been revoked.")
  } else if(isFuture(parseISO(r.due_at))) {
    color = "bg-orange-50 text-orange-500"
    status_txt = t("Payment due soon")
  }
  return <div className={`text-center font-semibold rounded-md p-4 text-sm ${color}`}>
    {status_txt}
  </div>

})

const DebtCollectionAtBox = withTranslation('fleet-management-admin')(function ({data:r, t}) {
  let color = "bg-green-50 text-green-500"
  let txt = t("No");

  if(r.debt_collection_at) {
    color = "bg-red-50 text-red-500"
    txt = format(parseISO(r.debt_collection_at), 'dd.MM.yyyy HH:mm')
  }

  return <div className={`text-center font-semibold rounded-md p-4 text-sm ${color}`}>
    {txt}
  </div>
})

const rePhoneNumber = /^(\+?\d{0,4})?\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{3}\)?)\s?-?\s?(\(?\d{4}\)?)?$/;
const receiverSchema = y.object({
  receiver_name: y.string().required().label("Receiver Name"),
  receiver_email: y.string().required().email("Please provide a valid email").label("Receiver Email"),
  receiver_mobile: y.string().required().matches(rePhoneNumber, "Please provide a valid phone number").label("Receiver Contact Phone")
});

const FineDetail = (props) => {

  const { t } = useT();

  const {state, handleChanges, handleSave, isLoading} = useResource(`/fines/${props.params.fineId}`)
  const data = Object.assign({ receiver_name: "", receiver_email: "", receiver_mobile: "" }, state.data, state.changes);

  const { list: fines, reloadListQuery} = useReloadList('/fines/list')

  const { errors } = useValidate(state, receiverSchema)

  const [appState, setAppState] = useRecoilState(AppStore)
  const [fineImageUrls, setFineImageUrls] = useState([])
  const [finePrintoutBlob, setFinePrintoutBlob ] = useState(null)


  useEffect(() => {
    if(state.data.fine_images && state.data.fine_images.length > 0 && appState.token) {
      getImageBlobs(appState.token, state.data.fine_images.map((i) => `${FM_API_URL}/fines/${data.id}/image/${i}`)).then((blobs) => {
        setFineImageUrls(blobs);
      })
    }

    if(state.data.fine_printout && state.data.fine_printout.length > 0 && appState.token) {
      getImageBlobs(appState.token, [`${FM_API_URL}/fines/${data.id}/image/${state.data.fine_printout}`]).then((blob) => {
        if(blob && blob.length > 0) {
          setFinePrintoutBlob(blob[0])
        }
      })
    }

    return () => {
      // Clean up!
      for (const i in fineImageUrls) {
        URL.revokeObjectURL(i);
      }
      setFineImageUrls([])

      URL.revokeObjectURL(finePrintoutBlob);
      setFinePrintoutBlob(null)

    }

  }, [state.data])

  if (Object.keys(data).length === 0 || isLoading) {
    return (
      <div className="flex flex-1 items-center justify-center max-h-full min-h-full bg-white p-6 place-items-center">
        <VehicleSpinner />
      </div>
    );
  }

  let hasChanges = !!data.receiver_name || !!data.receiver_email || !!data.receiver_mobile;
  let hasErrors = Object.keys(errors).length > 0;
  const receiverNotAllowed = (data.status === 'PAID' || data.status === 'CLOSED' || data.status === 'EXPIRED' || data.status === 'REVOKED' || data.status === 'REVOKED_INSPECTOR' || data.is_parking_fee)

  //
  // Compile receiver details
  //

  // TODO: Don't allow filling in fine REVOKED OR CLOSED
  const receiverHistory = <table className="m-2 border-collapse text-left text-sm text-gray-500 grid grid-cols-3">
    <thead className="contents">
    <tr className="grid col-span-3 items-center font-medium text-gray-900 border-gray-150 border-b-2" style={{gridTemplateColumns: "subgrid" }}>
      <th className="p-2">{t("Event")}</th><th className="p-2">{t("Date")}</th><th className="p-2">{t("Comment")}</th>
    </tr>
    </thead>
    <tbody className="contents">
    {data.receiver_at && <tr className="grid col-span-3" style={{gridTemplateColumns: "subgrid" }}>
      <td className="p-2">{t('Receiver data added')}</td>
      <td className="p-2">{format(parseISO(data.receiver_at), 'dd.MM.yyyy HH:mm')}</td>
      <td></td>
    </tr>}
    {data.notification_sent_at && <tr className="grid col-span-3" style={{gridTemplateColumns: "subgrid" }}>
      <td className="p-2">{t('Notification sent')}</td>
      <td className="p-2">{format(parseISO(data.notification_sent_at), 'dd.MM.yyyy HH:mm')}</td>
      <td className="p-2"><a href={`${FM_API_URL}/fines/${data.id}/notification/${data.notification_id}`} target="_blank" className="text-blue-600 hover:underline">{t('View message')}</a></td>
    </tr>}
    {data.debt_collection_at && <tr className="grid col-span-3" style={{gridTemplateColumns: "subgrid" }}>
      <td className="p-2">{t('Fine sent to debt collection')}</td>
      <td className="p-2">{format(parseISO(data.debt_collection_at), 'dd.MM.yyyy HH:mm')}</td>
      <td className="p-2"></td>
    </tr>}
    {!data.receiver_at && !data.notification_sent_at && !data.debt_collection_at &&
      <tr className="grid col-span-3" style={{gridTemplateColumns: "subgrid" }}>
      <td className="col-span-3 p-2"><div className="rounded border border-gray-150 p-4 text-center text-gray-400" style={{textShadow: "0px 1px 0px white"}}>{t("No receiver events yet")}</div></td>
    </tr>
    }
    </tbody>
  </table>

  let receiverDetails = <div className="bg-gray-50 rounded">
    <dl className="p-4">
      <dt className="mb-2 font-semibold leading-none text-gray-900">{t("Name")}</dt>
      <dd className="mb-2 font-light text-gray-500">{data.receiver_name}</dd>
      {data.receiver_id_code && <>
      <dt className="mb-2 font-semibold leading-none text-gray-900">{t("ID Code")}</dt>
      <dd className="mb-2 font-light text-gray-500">{data.receiver_id_code}</dd>
      </>}
      <dt className="mb-2 font-semibold leading-none text-gray-900">{t("Email")}</dt>
      <dd className="mb-2 font-light text-gray-500">{data.receiver_email}</dd>
      <dt className="mb-2 font-semibold leading-none text-gray-900">{t("Contact Mobile")}</dt>
      <dd className="mb-2 font-light text-gray-500">{data.receiver_mobile}</dd>
    </dl>
    {receiverHistory}
  </div>
  if (receiverNotAllowed) {
    receiverDetails = <div className="bg-gray-50 rounded">{receiverHistory}</div>
  } else if (!data.notification_sent_at && !data.debt_collection_at) {
    receiverDetails = <div className="bg-gray-50 rounded">
      <form className="p-2 block">
        <div className="pb-4">
          <TextInput id="receiver_name" value={data.receiver_name} onChange={handleChanges('receiver_name')}
                     placeholder={t("Name of fine receiver")} isInvalid={hasChanges && !!errors['receiver_name']}
                     isTouched={!!state.changes['receiver_name']}/>
          <SupportText helpMessage={t("Please fill in the full name of the fine receiver")}
                       errorMessage={hasChanges && errors['receiver_name']}/>
        </div>
        <div className="pb-4">
          <TextInput id="receiver_id_code" value={data.receiver_id_code} onChange={handleChanges('receiver_id_code')}
                     placeholder={t("Optional ID code of fine receiver")} isInvalid={hasChanges && !!errors['receiver_id_code']}
                     isTouched={!!state.changes['receiver_id_code']}/>
          <SupportText helpMessage={t("If available please fill in the Estonian ID code")}
                       errorMessage={hasChanges && errors['receiver_id_code']}/>
        </div>
        <div className="columns-2">
          <TextInput id="receiver_email" type="email" value={data.receiver_email}
                     onChange={handleChanges('receiver_email')} placeholder={t("Email of fine receiver")}
                     isInvalid={hasChanges && !!errors['receiver_email']}
                     isTouched={!!state.changes['receiver_email']}/>
          <SupportText helpMessage={t("We will send a copy of the fine to this email address")}
                       errorMessage={hasChanges && errors['receiver_email']}/>

          <TextInput id="receiver_mobile" type="tel" value={data.receiver_mobile}
                     onChange={handleChanges('receiver_mobile')} placeholder={t("Contact mobile of fine receiver")}
                     isInvalid={hasChanges && !!errors['receiver_mobile']}
                     isTouched={!!state.changes['receiver_mobile']}/>
          <SupportText helpMessage={t("Alternatively payment reminders are sent to this number")}
                       errorMessage={hasChanges && errors['receiver_mobile']}/>
        </div>
        <div className="mt-4 flex gap-4">
          <div className="-mt-2">
            <SupportText
              helpMessage={t("The fine will be sent to the customer after 30 minutes after pressing the button. When the fine has been sent the receiver details cannot be changed.")}/>
          </div>
          <div className="whitespace-nowrap">
            <button disabled={!hasChanges || hasErrors} type="button"
                    onClick={() => {
                      handleSave().then(() => {
                        reloadListQuery();
                      })
                    }}
                    className={`h-11 inline-flex items-center rounded border border-primary-100 bg-primary-100 px-4 py-2.5 text-center text-sm font-medium text-primary-600 transition-all hover:border-primary-200 hover:bg-primary-200 focus:ring focus:ring-primary-50 disabled:border-primary-50 disabled:bg-primary-50 disabled:text-primary-400 ${hasErrors && ' border-red-50 bg-red-50 text-red-300 disabled:border-red-50 disabled:bg-red-50 disabled:text-red-300'}`}>
              <CircleStackIcon
                className={`h-5 w-5 text-blue-500 mr-2 ${hasErrors && ' text-red-300 '}`}/>{t("Save and Send the Fine")}
            </button>
          </div>
        </div>
      </form>
      {receiverHistory}
    </div>
  }


  //{data.fine_data.zone.longitude && data.fine_data.zone.latitude && <Map center={[data.fine_data.zone.longitude, data.fine_data.zone.latitude]} geojson={data.geojson}/>}
  //

  //
  // Display details
  //

  //console.log('FineDetail', props, data, 'errors: ', errors, hasErrors)


  return <Form state={state}>
    <FormTitle title={String(data.number).replace(re, '$1 $2 $3 $4')} subtitle={String(data.subject).replace(/\s/g, '').replace(re2, "$1 ").replace(re3, "$1 ") + ' (' + data.fleet_name + ')' }/>
    <FormDetails className="grid gap-4 grid-cols-2" data={{
      "issued_by_code": {
        "label": t("Issued By"),
        "display": data.issued_by_code
      },
      "issued_at": {
        "label": t("Issued At"),
        "display": data.issued_at ? format(parseISO(data.issued_at), 'dd.MM.yyyy HH:mm') : '--'
      },
      "due_at": {
        "label": t("Due At"),
        "display": data.due_at ? format(parseISO(data.due_at), 'dd.MM.yyyy HH:mm') : '--',
        //"className": "col-span-2"
      },
      "discount_expires_at": {
        "label": t("Discount Expires At"),
        "display": data.discount_expires_at ? format(parseISO(data.discount_expires_at), 'dd.MM.yyyy HH:mm') : '--',
        //"className": "col-span-2"
      },
      "reason": {
        "label": t("Reason"),
        "display": data.reason
      },
      "details": {
        "label": t("Details"),
        "display": data.details || '--'
      },
      "payment_status": {
        "label": t("Status"),
        "display": <FineStatusBox data={data} />,
        //"className": "col-span-2"
      },
      "debt_collection_at": {
        "label": t("Sent to Debt Collection"),
        "display": <DebtCollectionAtBox data={data} />,
        //"className": "col-span-2"
      },
      "amount_due": {
        "label": t("Amount Due"),
        "display": `${data.amount_due} €`
      },
      "amount_paid": {
        "label": t("Amount Paid"),
        "display": `${data.amount_paid} €` + (data.payment_date ? format(parseISO(data.payment_date), ' (dd.MM.yyyy HH:mm)') : '')
      },
      "discount_amount": {
        "label": t("Discount Amount"),
        "display": data.discount_amount ? `${data.discount_amount} €` : '--'
      },
      "amount": {
        "label": t("Full Amount"),
        "display": `${data.amount} €`
      },
      "receiver": {
        "label": t("Receiver Info"),
        "display": receiverDetails,
        "className": "col-span-2"
      },
      "zone": {
        "label": t("Parking Area"),
        "display": data.locality_name,
        "className": "col-span-2"
      }
    }} />
    {state.data.geojson && <div className="mb-6">
      <Map geojson={state.data.geojson} zoom={15}/>
    </div>}
    {state.data.fine_images && <div>
      <h3 className="text-lg mb-4 font-semibold leading-none text-gray-900">
        {t('Images')}
      </h3>
      {fineImageUrls.length === 0 ? <Spinner /> :
        <div className="grid gap-4 grid-cols-2">
          {fineImageUrls.map((i, idx) => <div key={idx} className="relative">
            <a href={i} download={`${data.id}_${idx}.jpg`}
               className="drop-shadow-lg opacity-85 z-10 absolute h-8 w-8 mr-2 mt-2 right-0 bg-white rounded-full inline-flex items-center gap-0.5 text-center text-sm font-medium text-blue-500 transition-all hover:underline"><ArrowDownTrayIcon
              className="h-5 w-5 text-blue-500 m-auto"/></a>

            <img src={i} alt={`Fine image ${idx}`} className="drop-shadow-lg"/>
          </div>)}
        </div>
      }
    </div>
    }
    {state.data.fine_printout && <div>
      <h3 className="text-lg my-4 font-semibold leading-none text-gray-900">
        {t('Fine Printout')}
      </h3>
      {!finePrintoutBlob ? <Spinner/> :
        <div className="max-w-[50%] pr-2">
          <a href={finePrintoutBlob} download={`${data.id}_fine_printout.jpg`} className="h-11 inline-flex items-center gap-0.5 text-center text-sm font-medium text-blue-500 transition-all hover:underline"><ArrowDownTrayIcon className="h-5 w-5 text-blue-500 mr-2" />{t('Download')}</a>
        <img src={finePrintoutBlob} alt="Fine Printout" className="mt-5 drop-shadow-lg" />
      </div>}
    </div>}
  </Form>
}

export default  FineDetail;
