import Split from 'react-split'
import { useT } from "./components/languages/t";
import { TopNav } from "./views/TopNav";
import { SideNav } from "./views/SideNav";
import { useRecoilState, useRecoilValue } from "recoil";
import { AppStore } from "./store/store";

import { ArrowLongLeftIcon } from '@heroicons/react/24/outline'

import { Switch, Route, Redirect, useRoute, useLocation } from "wouter";

import Login from "./routes/login/Login";
import VehicleList from "./routes/vehicles/VehicleList";
import VehicleDetail from "./routes/vehicles/VehicleDetail";
import FineList from "./routes/fines/FineList";
import FineDetail from "./routes/fines/FineDetail";
import Map from "./routes/map-new/Map";

import UserList from "./routes/users/UserList";
import UserDetail from "./routes/users/UserDetail";
import Logout from "./routes/login/Logout";
import ParkingList from "./routes/parkings/ParkingList";
import ParkingDetail from "./routes/parkings/ParkingDetail";
import ReportsList from './routes/reports/ReportsList';
import ReportsDetails from './routes/reports/ReportsDetails';
import { SideNavTop } from './views/SideTopNav';

export const App = ({ theme }) => {

  const { t } = useT();
  const [appState, setAppState] = useRecoilState(AppStore)
  const [location, _] = useLocation();
  const match = !!String(location).match(/^[/](map|parking)/);

  const isSpinnTheme = theme === 'Spinn';

  if (!appState.token) {
    return <Login />
  }

  return <>
    <div className='mainApp'>
      {isSpinnTheme ? <SideNav appState={appState} /> : <TopNav appState={appState} />}
      {isSpinnTheme && <SideNavTop appState={appState} />}
      {match ? (
        <div id='mainContent' className="flex flex-col bg-white" style={isSpinnTheme ? { "height": "100%" } : { "height": "calc(100% - 100px)" }}>
          <Switch>
            <Route path="/parking/:rest*" component={ParkingList} />
            <Route path="/map/:rest*" component={Map} />
          </Switch>
        </div>
      ) : (
        <Split
          style={isSpinnTheme ? { "maxHeight": "100vh" } : { "maxHeight": "calc(100% - 100px)" }}
          className="flex p-2 grow"
          id='mainContent'
          sizes={[60, 40]}
          minSize={[300,550]}
          gutterSize={10}
          gutterAlign="center"
          direction="horizontal"
          cursor="col-resize"
        >
          <section className="flex flex-col bg-white max-h-full relative">
            <Switch>
              <Route path="/logout" component={Logout} />
              <Route path="/parking/:rest*" component={ParkingList} />
              <Route path="/vehicles/:rest*" component={VehicleList} />
              <Route path="/fines/:rest*" component={FineList} />
              <Route path="/users/:rest*" component={UserList} />
              <Route path="/reports/:rest*" component={ReportsList} />
              <Redirect
                from="/"
                to={((appState.operators || []).length > 0  || appState.can_view_sessions) ? "/map" : "/fines"} />
            </Switch>
          </section><section className="bg-white max-h-full overflow-y-auto overflow-x-hidden">
            <div className="flex min-h-full">
              <Switch>
                <Route path="/parking/:sessionId" component={ParkingDetail} />
                <Route path="/vehicles/:vehicleId" component={VehicleDetail} />
                <Route path="/fines/:fineId" component={FineDetail} />
                <Route path="/users/:userId" component={UserDetail} />
                <Route path="/reports/:reportId" component={ReportsDetails} />
                <Route><div className="flex flex-1 items-center justify-center max-h-full min-h-full bg-white text-gray-400 p-6 place-items-center">
                  <ArrowLongLeftIcon className="h-5 w-5 text-gray-400 mr-2" />
                  {t('Choose a row to the left')}
                </div></Route>
              </Switch>
            </div>
          </section>
        </Split>
      )}
    </div>
  </>

}
